import React from 'react'
import styled from 'styled-components'

/*
 * The <ResponsiveVideo> component returns a <li> element and should be used inside an <ol> or <ul>
 * The component should receive an object: {...object} which contains:
 * * videoProvider
 * * link
 * * name (optional)
 * * duration (optional)
 */

/*
 * Right now this component is compatable with 16:9 and 9:16 aspect ratios.
 * More aspect ratios can be added below if necessary
*/

const PortraitContainer = styled.li`
  position: relative;
  overflow: hidden;
  width: 90%;
  padding-top: 160%;
  margin: auto;

  @media screen and (min-width: ${props => props.theme.responsive.small}) {
    width: 70%;
    padding-top: 124.44%;
  }

  @media screen and (min-width: ${props => props.theme.responsive.medium}) {
    width: 50%;
    padding-top: 88.89%;
  }
`

const LandscapeContainer = styled.li`
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%;
  margin: auto;
`

const VideoPlayer = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
`

const ResponsiveVideo = ({ videoProvider, link, ...props }) => {
  if (props.width > props.height) {
    return (
      <>
        {props.name?.match(/Reunion 20[0-9]{2}/g) &&
          <h3 className="section-title">{props.name}</h3>
        }
        <LandscapeContainer>
          <VideoPlayer
            src={props.player_embed_url ?? link}
            frameBorder={'0'}
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen>
          </VideoPlayer>
        </LandscapeContainer>
      </>
    )
  }
  else {
    return (
      <>
        {props.name?.match(/Reunion 20[0-9]{2}/g) &&
          <h3 className="section-title">{props.name}</h3>
        }
        <PortraitContainer>
          <VideoPlayer
            src={props.player_embed_url ?? link}
            frameBorder={'0'}
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen>
          </VideoPlayer>
        </PortraitContainer>
      </>
    )
  }
}

export default ResponsiveVideo
