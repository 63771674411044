import React from 'react'
import styled from 'styled-components'
import ResponsiveVideo from './ResponsiveVideo'

/*
 * The <Videos> component returns a <ul> element and should be used around <li> element(s)
 * The <li> element(s) are received as `props.children`
 */

/**
 * @example
 * <Videos> // a <ul> element
 *   {videos.map(({ node: video }) => (
 *     <ResponsiveVideo key={video.resource_key} {...video} />
 *   ))}
 * </Videos>
 */

const List = styled.ul`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 30px;
  width: 100%;
  max-width: ${props => props.theme.containerWidths.mediumLarge};
  margin-left: auto;
  margin-right: auto;

  @media screen and (min-width: ${props => props.theme.containerWidths.large}) {
    grid-gap: 50px;
  }
`

const Videos = ({ videos, ...props }) => (
  <List {...props}>
    {videos.map((video, index) => (
      <React.Fragment key={video.resource_key}>
        {props.dataShowTitle &&
          <h2 style={{ position: 'relative', top: '30px' }}>{video.name}</h2>
        }
        <ResponsiveVideo {...video} videoProvider="vimeo">
          {props.pdfNodes &&
          props.indexesWithPdf &&
          props.indexesWithPdf.indexOf(index) !== -1
            ? props.indexesWithPdf
                .filter(pdfIndex => pdfIndex === index)
                .map((i, loopIndex) => (
                  <p
                    key={
                      props.pdfNodes[
                        props.indexesWithPdf.indexOf(i) + loopIndex
                      ].id
                    }
                  >
                    <a
                      href={
                        props.pdfNodes[
                          props.indexesWithPdf.indexOf(i) + loopIndex
                        ].file.url
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {
                        props.pdfNodes[
                          props.indexesWithPdf.indexOf(i) + loopIndex
                        ].title
                      }{' '}
                      PDF
                    </a>
                  </p>
                ))
            : null}
        </ResponsiveVideo>
      </React.Fragment>
    ))}
  </List>
)

export default Videos
